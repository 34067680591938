import React from "react";
import {
  Badge,
  Avatar,
  Divider,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  Link,
} from "@material-ui/core";
import "./index.scss";
import { appStore } from "models";
import { observer } from "mobx-react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { getPlan } from "config/plans";
import { get, noop } from "lodash-es";
import ProBadge from "icons/pro-badge";
import BasicBadge from "icons/basic-badge";
import BackArrow from "icons/back";
import useDialog from "components/Dialog/useDialog";
import { topNavLinks } from "layouts/TopBar";

interface Props {
  handleBackClick?: any;
}

const navs = [
  { text: "Account Overview", url: "/home" },
  { text: "Edit Profile", url: "/profile" },
  { text: "Plan Summary", url: "/plan-summary" },
  { text: "Manage Subscription", url: "/subscription" },
  { text: "Promo Code", url: "/promo-code" },
  { text: "HealthiCare", url: "/healthicare" },
];

const LeftNav: React.FC<Props> = ({ handleBackClick = noop }) => {
  const location = useLocation();
  const user = appStore.currentUser;
  const history = useHistory();
  const plan = getPlan(user.lossPlan);
  const { openDialog } = useDialog();
  const handleLogout = () => {
    openDialog({
      subTitle: "Sign Out",
      title: "Are you sure you want to sign out?",
    })
      .then((result) => {
        if (result) {
          appStore.logout();
          history.push("/login");
        }
      })
      .catch(noop);
  };

  return (
    <div className="left-nav content-box">
      <IconButton className="left-nav-back" onClick={handleBackClick}>
        <BackArrow />
      </IconButton>
      {user.isProUser ? (
        <ProBadge className="user-sub-badge" />
      ) : (
        <BasicBadge className="user-sub-badge" />
      )}
      <div className={`user-info ${get(plan, "className")}`}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            <div className="avatar-plan-icon plan-icon plan-icon-bg" />
          }
        >
          <Avatar
            alt={user.name}
            onClick={() => {
              history.push("/profile");
              handleBackClick();
            }}
            className="user-avatar"
            src={user.profilePhotoUrl}
          >
            <img
              src="/images/default-avatar.svg"
              className="user-avatar-fallback"
              alt=""
            />
          </Avatar>
        </Badge>
        <div className="user-name">{user.name}</div>
        <div className="user-username">{user.username}</div>
      </div>
      <Divider className="divider"></Divider>
      <MenuList className="nav-list">
        {navs
          .filter((link) => !user.isProUser || link.url !== "/promo-code")
          .map(({ text, url }) => (
            <NavLink key={url} to={url} onClick={handleBackClick}>
              <MenuItem
                className="nav-link"
                selected={location.pathname.indexOf(url) > -1}
              >
                {text}
              </MenuItem>
            </NavLink>
          ))}
      </MenuList>
      <Divider className="divider left-nav-mobile-divider"></Divider>
      <div className="left-nav-mobile-links">
        {topNavLinks.map(({ text, url }) => (
          <Link
            key={url}
            href={url}
            className="left-nav-mobile-link"
            target="_blank"
          >
            {text}
          </Link>
        ))}
      </div>
      <Button className="btn-red btn-sign-out" onClick={handleLogout}>
        Sign out
      </Button>
    </div>
  );
};

export default observer(LeftNav);
