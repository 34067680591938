import React, { useState, useEffect } from "react";
import { appStore } from "models";
import {
  PaymentElement,
  AddressElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  // useEffect(() => {
  //   setEmail(appStore.currentUser.email);
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // const result = await stripe.confirmSetup({
    //   elements,
    //   redirect: "if_required",
    //   confirmParams: {
    //     // Make sure to change this to your payment completion page
    //     return_url: "http://localhost:3000/complete",
    //     payment_method_data: { billing_details: { email: email } },
    //   },
    // });

    // const error = result?.error;
    // if (error) {
    //   if (error.type === "validation_error") {
    //     // do nothing, validation errors are handled by stripe elements UI
    //   } else {
    //     throw new Error(error.message);
    //   }
    // } else {
    //   const setupIntent = result?.setupIntent;
    //   if (setupIntent && setupIntent.status === "succeeded") {
    //     setMessage(`Payment succeeded with ${setupIntent.id}`);
    //     // send setupIntent.id to CareValidate endpoint
    //   } else {
    //     setMessage(error.message);
    //     throw Error("Payment unsuccessful!");
    //   }
    // }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        onChange={(e) => setEmail(e.value.email.trim())}
      />
      <AddressElement
        options={{ mode: "shipping", allowedCountries: ["US"] }}
      />
      <PaymentElement id="payment-element" />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        style={{ marginTop: "20px" }}
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
