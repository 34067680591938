import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Box from "@material-ui/core/Box";
import GoogleGA from "helpers/googleGA";
import Http from "../../api/Http";
import CheckoutForm from "./CheckoutForm";
import "./index.scss";

const stripePromise = loadStripe(
  // "pk_test_51HqSIiKAXrtjbq2dUipJMaSqisS96Auqj0wFVcuuwtD75gC0Jof2HIlmG8RoGYLlAToyPeIDOqMsjTLUfgXLNh1U00lrHRJ5Bl",
  "pk_live_51HqSIiKAXrtjbq2dtXcGLkFqhqPquraau6jRB8nDCrDVIGj7me2ZEAiQxZNwuG9A7Y1Gzn6vg8xslQuCpoTByMKd00cmPemstt",
);

// const initiateEndpoint = `https://cors-anywhere.herokuapp.com/${Http.defaults.baseURL}/web/carevalidate-initiate-payment`;
const initiateEndpoint = `${Http.defaults.baseURL}/web/carevalidate-initiate-payment`;

const StripePaymentView: React.FC = () => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Define the async function inside useEffect
    const initializePayment = async () => {
      try {
        const response = await fetch(initiateEndpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        });
        const data = await response.json();
        console.log("Received data:", data);
        setClientSecret(data.paymentSecret);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    // Call the function
    initializePayment();

    GoogleGA.logEvent("healthicare-initiate-payment");
  }, []); // Empty dependency array means this runs once on mount

  const appearance: { theme: "flat" | "stripe" | "night" | "none" } = {
    theme: "stripe",
  };
  const loader = "auto";

  return (
    <div className="simple-card-page">
      <Box className="simple-card-page-title">HealthiCare payment</Box>
      <Box className="simple-card-page-description">
        Please enter your payment details to start HealthiCare!
      </Box>
      {/* <p>Client Secret: {clientSecret}</p>
      <p>Stripe Promise: {stripePromise ? "Loaded" : "Loading..."}</p> */}
      <div className="StripePaymentView">
        {clientSecret && (
          <Elements
            options={{ clientSecret, /*appearance, loader*/ }}
            stripe={stripePromise}
          >
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default StripePaymentView;
