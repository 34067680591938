import AccountOverview from "views/AccountOverview";
import EditProfile from "views/EditProfile";
import PlanSummary from "views/PlanSummary";
import ManageSubscription from "views/ManageSubscription";
import PromoCode from "views/PromoCode";

import Login from "views/Login";
import SignUp from "views/SignUp";
import NotFound from "views/NotFound";
import ForgetPassword from "views/ForgetPassword";
import ResetPassword from "views/ResetPassword";
import Setup from "views/Setup";
import ChoosePlan from "views/ChoosePlan";
import HealthiCare from "views/HealthiCare";

export default {
  mainLayout: [
    { path: "/home", component: AccountOverview, guarded: true },
    { path: "/profile", component: EditProfile, guarded: true },
    { path: "/plan-summary", component: PlanSummary, guarded: true },
    { path: "/subscription", component: ManageSubscription, guarded: true },
    { path: "/promo-code", component: PromoCode, guarded: true },
    // { path: "/healthicare", component: HealthiCare, guarded: false },
  ],
  singleLayout: [
    { path: "/login", component: Login, guarded: false },
    { path: "/signup", component: SignUp, guarded: false },
    { path: "/setup", component: Setup, guarded: false },
    { path: "/choose-plan", component: ChoosePlan, guarded: true },
    { path: "/forget-password", component: ForgetPassword, guarded: false },
    { path: "/reset-password", component: ResetPassword, guarded: false },
    { path: "/healthicare", component: HealthiCare, guarded: false },
  ],
  notFound: NotFound,
};
